export class ZipCodes {
  static getCoordinatesByZipCode() {
    return {
      "7310": {"lat": 46.99376549999999, "lon": 9.4655155},
      "7312": {"lat": 46.9886507, "lon": 9.4974937},
      "7313": {"lat": 46.97804, "lon": 9.51272},
      "7314": {"lat": 46.9574335, "lon": 9.4848799},
      "7315": {"lat": 46.98094409999999, "lon": 9.4919576},
      "7317": {"lat": 46.95290000000001, "lon": 9.47763},
      "7320": {"lat": 47.0540051, "lon": 9.4488629},
      "7323": {"lat": 47.0125249, "lon": 9.4166632},
      "7324": {"lat": 47.0243717, "lon": 9.451234099999999},
      "7325": {"lat": 47.012173, "lon": 9.3614807},
      "7326": {"lat": 46.990556, "lon": 9.344444},
      "8638": {"lat": 47.2794582, "lon": 8.9709262},
      "8640": {"lat": 47.2279741, "lon": 8.8255821},
      "8645": {"lat": 47.23089239999999, "lon": 8.8370062},
      "8646": {"lat": 47.231038, "lon": 8.886396999999999},
      "8715": {"lat": 47.219631, "lon": 8.893951},
      "8716": {"lat": 47.2297285, "lon": 8.9460319},
      "8717": {"lat": 47.1892867, "lon": 8.991218},
      "8718": {"lat": 47.1582369, "lon": 9.072896499999999},
      "8722": {"lat": 47.2161875, "lon": 9.0242024},
      "8723": {"lat": 47.1811841, "lon": 9.0508609},
      "8725": {"lat": 47.2451799, "lon": 8.9998043},
      "8726": {"lat": 47.26305600000001, "lon": 9.044167},
      "8727": {"lat": 47.27041, "lon": 9.010909999999999},
      "8730": {"lat": 47.2243722, "lon": 8.9841417},
      "8732": {"lat": 47.239799, "lon": 8.9431058},
      "8733": {"lat": 47.24383419999999, "lon": 8.9230079},
      "8734": {"lat": 47.2447773, "lon": 8.884997199999999},
      "8735": {"lat": 47.26580209999999, "lon": 8.988006},
      "8737": {"lat": 47.22842079999999, "lon": 9.0250434},
      "8738": {"lat": 47.24191, "lon": 9.02562},
      "8739": {"lat": 47.23159219999999, "lon": 9.0780576},
      "8872": {"lat": 47.1362592, "lon": 9.1052748},
      "8873": {"lat": 47.1697672, "lon": 9.174439699999999},
      "8877": {"lat": 47.112897, "lon": 9.2150942},
      "8878": {"lat": 47.1296, "lon": 9.21585},
      "8879": {"lat": 47.0421211, "lon": 9.4312979},
      "8880": {"lat": 47.124147, "lon": 9.312686099999999},
      "8881": {"lat": 47.14085, "lon": 9.28673},
      "8882": {"lat": 47.1128526, "lon": 9.2553884},
      "8883": {"lat": 47.1012684, "lon": 9.231723299999999},
      "8884": {"lat": 47.102403, "lon": 9.255855000000002},
      "8885": {"lat": 47.11255000000001, "lon": 9.27914},
      "8886": {"lat": 47.04915, "lon": 9.394549999999999},
      "8887": {"lat": 47.0437983, "lon": 9.4240041},
      "8888": {"lat": 47.0580146, "lon": 9.411904},
      "8889": {"lat": 47.0562, "lon": 9.399469999999999},
      "8890": {"lat": 47.0925191, "lon": 9.3390396},
      "8892": {"lat": 47.1059727, "lon": 9.3465229},
      "8893": {"lat": 47.08218, "lon": 9.37356},
      "8894": {"lat": 47.07344, "lon": 9.35182},
      "8895": {"lat": 47.07791, "lon": 9.333789999999999},
      "8896": {"lat": 47.09466, "lon": 9.31317},
      "8897": {"lat": 47.0881, "lon": 9.30517},
      "8898": {"lat": 47.09224, "lon": 9.28769},
      "9000": {"lat": 47.42456259999999, "lon": 9.3748965},
      "9001": {"lat": 47.4229096, "lon": 9.373549299999999},
      "9002": {"lat": 47.4244818, "lon": 9.3767173},
      "9003": {"lat": 47.4244818, "lon": 9.3767173},
      "9004": {"lat": 47.4244818, "lon": 9.3767173},
      "9005": {"lat": 47.4244818, "lon": 9.3767173},
      "9006": {"lat": 47.4244818, "lon": 9.3767173},
      "9007": {"lat": 47.4244818, "lon": 9.3767173},
      "9008": {"lat": 47.43933209999999, "lon": 9.390634799999999},
      "9009": {"lat": 47.4244818, "lon": 9.3767173},
      "9010": {"lat": 47.44214849999999, "lon": 9.3714336},
      "9011": {"lat": 47.41196180000001, "lon": 9.4078687},
      "9012": {"lat": 47.40929970000001, "lon": 9.3651991},
      "9013": {"lat": 47.4244818, "lon": 9.3767173},
      "9014": {"lat": 47.4082855, "lon": 9.332389},
      "9015": {"lat": 47.4080948, "lon": 9.3045072},
      "9016": {"lat": 47.4408258, "lon": 9.417473},
      "9017": {"lat": 47.4244818, "lon": 9.3767173},
      "9018": {"lat": 47.4244818, "lon": 9.3767173},
      "9019": {"lat": 47.4244818, "lon": 9.3767173},
      "9020": {"lat": 47.4244818, "lon": 9.3767173},
      "9021": {"lat": 47.4244818, "lon": 9.3767173},
      "9022": {"lat": 47.4244818, "lon": 9.3767173},
      "9023": {"lat": 47.4244818, "lon": 9.3767173},
      "9024": {"lat": 47.4244818, "lon": 9.3767173},
      "9025": {"lat": 47.4244818, "lon": 9.3767173},
      "9026": {"lat": 47.4244818, "lon": 9.3767173},
      "9027": {"lat": 47.4244818, "lon": 9.3767173},
      "9028": {"lat": 47.4244818, "lon": 9.3767173},
      "9029": {"lat": 47.4244818, "lon": 9.3767173},
      "9030": {"lat": 47.4227854, "lon": 9.317473399999999},
      "9032": {"lat": 47.44285, "lon": 9.344249999999999},
      "9033": {"lat": 47.45707489999999, "lon": 9.460118399999999},
      "9034": {"lat": 47.43948169999999, "lon": 9.463542499999999},
      "9036": {"lat": 47.4524984, "lon": 9.513329599999999},
      "9113": {"lat": 47.36995830000001, "lon": 9.1964005},
      "9114": {"lat": 47.3626523, "lon": 9.174986299999999},
      "9115": {"lat": 47.3371567, "lon": 9.1915275},
      "9116": {"lat": 47.3945398, "lon": 9.1831175},
      "9122": {"lat": 47.35013, "lon": 9.15137},
      "9123": {"lat": 47.3730972, "lon": 9.135993299999999},
      "9125": {"lat": 47.3226115, "lon": 9.1410716},
      "9126": {"lat": 47.3490406, "lon": 9.1308227},
      "9127": {"lat": 47.319723, "lon": 9.1770198},
      "9200": {"lat": 47.4151524, "lon": 9.248133600000001},
      "9201": {"lat": 47.4155608, "lon": 9.248852},
      "9203": {"lat": 47.4379415, "lon": 9.1976956},
      "9204": {"lat": 47.43244929999999, "lon": 9.2785092},
      "9205": {"lat": 47.4613393, "lon": 9.2879882},
      "9212": {"lat": 47.4433126, "lon": 9.255587},
      "9230": {"lat": 47.4103868, "lon": 9.1848747},
      "9231": {"lat": 47.39078, "lon": 9.2252},
      "9234": {"lat": 63.05674190000001, "lon": 9.212157699999999},
      "9240": {"lat": 47.4354501, "lon": 9.1376811},
      "9242": {"lat": 47.4279701, "lon": 9.1196722},
      "9243": {"lat": 47.42772, "lon": 9.0918706},
      "9244": {"lat": 47.4445793, "lon": 9.1412359},
      "9245": {"lat": 47.4518136, "lon": 9.161289199999999},
      "9246": {"lat": 47.4619009, "lon": 9.220146699999999},
      "9247": {"lat": 47.45423, "lon": 9.116971},
      "9248": {"lat": 47.42102, "lon": 9.13529},
      "9249": {"lat": 47.45132, "lon": 9.08365},
      "9300": {"lat": 47.4630655, "lon": 9.3876654},
      "9302": {"lat": 47.4605542, "lon": 9.393344299999999},
      "9303": {"lat": 47.4598793, "lon": 9.3899898},
      "9304": {"lat": 47.47191189999999, "lon": 9.3365008},
      "9305": {"lat": 47.4883057, "lon": 9.4115634},
      "9308": {"lat": 47.4967895, "lon": 9.3536283},
      "9312": {"lat": 47.48944789999999, "lon": 9.337500100000002},
      "9313": {"lat": 47.5181777, "lon": 9.3190716},
      "9323": {"lat": 47.50321, "lon": 9.442079999999999},
      "9327": {"lat": 47.48480499999999, "lon": 9.4538659},
      "9400": {"lat": 47.4788825, "lon": 9.4916808},
      "9401": {"lat": 47.4788825, "lon": 9.4916808},
      "9402": {"lat": 47.4697047, "lon": 9.4270931},
      "9403": {"lat": 47.47883110000001, "lon": 9.4700004},
      "9404": {"lat": 47.4688795, "lon": 9.4869146},
      "9422": {"lat": 47.48052999999999, "lon": 9.53303},
      "9423": {"lat": 47.4899373, "lon": 9.5511292},
      "9424": {"lat": 47.4688761, "lon": 9.5853124},
      "9425": {"lat": 47.4800263, "lon": 9.570414099999999},
      "9430": {"lat": 47.4503633, "lon": 9.628829099999999},
      "9434": {"lat": 47.4295975, "lon": 9.6404297},
      "9435": {"lat": 47.411037, "lon": 9.6256262},
      "9436": {"lat": 47.4054637, "lon": 9.6042275},
      "9437": {"lat": 47.3851158, "lon": 9.581794300000002},
      "9442": {"lat": 47.4246872, "lon": 9.6107587},
      "9443": {"lat": 47.4024006, "lon": 9.64049},
      "9444": {"lat": 47.382165, "lon": 9.646025},
      "9445": {"lat": 47.3977266, "lon": 9.582709999999999},
      "9450": {"lat": 47.3866111, "lon": 9.5527175},
      "9451": {"lat": 47.364755, "lon": 9.608249899999999},
      "9452": {"lat": 47.35852, "lon": 9.5319},
      "9453": {"lat": 47.3459394, "lon": 9.518951999999999},
      "9462": {"lat": 47.3341624, "lon": 9.5848066},
      "9463": {"lat": 47.320266, "lon": 9.567072999999999},
      "9464": {"lat": 47.27691739999999, "lon": 9.5158776},
      "9465": {"lat": 47.237082, "lon": 9.501076699999999},
      "9466": {"lat": 47.2655237, "lon": 9.5073787},
      "9467": {"lat": 47.24509, "lon": 9.47345},
      "9468": {"lat": 47.2271607, "lon": 9.4507613},
      "9469": {"lat": 47.21147, "lon": 9.491349999999999},
      "9470": {"lat": 47.168333, "lon": 9.463056},
      "9471": {"lat": 47.1655403, "lon": 9.4708594},
      "9472": {"lat": 47.1713913, "lon": 9.3958216},
      "9473": {"lat": 47.2039131, "lon": 9.441321799999999},
      "9475": {"lat": 47.1251828, "lon": 9.478797199999999},
      "9476": {"lat": 47.0904873, "lon": 9.4896553},
      "9477": {"lat": 47.0744276, "lon": 9.4787014},
      "9478": {"lat": 47.0909084, "lon": 9.418216},
      "9479": {"lat": 47.0918088, "lon": 9.477679799999999},
      "9500": {"lat": 47.4604542, "lon": 9.0530887},
      "9501": {"lat": 47.4635617, "lon": 9.0479097},
      "9512": {"lat": 47.47528130000001, "lon": 9.0606881},
      "9523": {"lat": 47.4658487, "lon": 9.0843586},
      "9524": {"lat": 47.4759087, "lon": 9.1073638},
      "9525": {"lat": 47.47472, "lon": 9.149619999999999},
      "9526": {"lat": 47.48450709999999, "lon": 9.1613138},
      "9527": {"lat": 47.4797585, "lon": 9.182697},
      "9533": {"lat": 47.4175617, "lon": 9.033230999999999},
      "9534": {"lat": 47.39742, "lon": 9.00179},
      "9536": {"lat": 47.4423658, "lon": 9.0764333},
      "9552": {"lat": 47.4816466, "lon": 9.0352172},
      "9601": {"lat": 47.38433, "lon": 9.07255},
      "9602": {"lat": 47.4126975, "lon": 9.0685947},
      "9604": {"lat": 47.39691269999999, "lon": 9.1065939},
      "9606": {"lat": 47.36032609999999, "lon": 9.0702514},
      "9607": {"lat": 47.3631793, "lon": 9.0343127},
      "9608": {"lat": 47.3810888, "lon": 9.0895852},
      "9612": {"lat": 47.3736258, "lon": 9.0175734},
      "9613": {"lat": 47.3707538, "lon": 8.9851668},
      "9614": {"lat": 47.32868999999999, "lon": 9.022879999999999},
      "9615": {"lat": 47.34729, "lon": 9.07955},
      "9620": {"lat": 47.3239036, "lon": 9.087684399999999},
      "9621": {"lat": 47.3478411, "lon": 9.1031113},
      "9622": {"lat": 47.3131873, "lon": 9.0428982},
      "9630": {"lat": 47.2901615, "lon": 9.0845857},
      "9631": {"lat": 47.28513359999999, "lon": 9.1167071},
      "9633": {"lat": 47.3005308, "lon": 9.1757408},
      "9642": {"lat": 47.2522495, "lon": 9.1275795},
      "9643": {"lat": 47.24696, "lon": 9.17199},
      "9650": {"lat": 47.2081104, "lon": 9.1868699},
      "9651": {"lat": 47.24011, "lon": 9.21088},
      "9652": {"lat": 47.22978, "lon": 9.19435},
      "9655": {"lat": 47.1999372, "lon": 9.227132899999999},
      "9656": {"lat": 47.1811945, "lon": 9.276218199999999},
      "9657": {"lat": 47.19716529999999, "lon": 9.3086091},
      "9658": {"lat": 47.2033339, "lon": 9.350492}
    }
  }

  static getStGallenCitiesByZipCode() {
    return [
      {value: '7310', text: '7310 Bad Ragaz'},
      {value: '7312', text: '7312 Pfäfers'},
      {value: '7313', text: '7313 St. Margrethenberg'},
      {value: '7314', text: '7314 Vadura'},
      {value: '7315', text: '7315 Vättis'},
      {value: '7317', text: '7317 Valens'},
      {value: '7317', text: '7317 Vasön'},
      {value: '7320', text: '7320 Sargans'},
      {value: '7323', text: '7323 Wangs'},
      {value: '7324', text: '7324 Vilters'},
      {value: '7325', text: '7325 Schwendi Weisstannental'},
      {value: '7326', text: '7326 Weisstannen'},
      {value: '8638', text: '8638 Goldingen'},
      {value: '8640', text: '8640 Rapperswil SG'},
      {value: '8640', text: '8640 Kempraten'},
      {value: '8645', text: '8645 Jona'},
      {value: '8646', text: '8646 Wagen'},
      {value: '8715', text: '8715 Bollingen'},
      {value: '8716', text: '8716 Schmerikon'},
      {value: '8717', text: '8717 Benken SG'},
      {value: '8718', text: '8718 Schänis'},
      {value: '8722', text: '8722 Kaltbrunn'},
      {value: '8723', text: '8723 Rufi'},
      {value: '8725', text: '8725 Ernetschwil'},
      {value: '8725', text: '8725 Gebertingen'},
      {value: '8726', text: '8726 Ricken SG'},
      {value: '8727', text: '8727 Walde SG'},
      {value: '8730', text: '8730 Uznach'},
      {value: '8732', text: '8732 Neuhaus SG'},
      {value: '8733', text: '8733 Eschenbach SG'},
      {value: '8734', text: '8734 Ermenswil'},
      {value: '8735', text: '8735 St. Gallenkappel'},
      {value: '8735', text: '8735 Rüeterswil'},
      {value: '8737', text: '8737 Gommiswald'},
      {value: '8738', text: '8738 Uetliburg SG'},
      {value: '8739', text: '8739 Rieden SG'},
      {value: '8872', text: '8872 Weesen'},
      {value: '8873', text: '8873 Amden'},
      {value: '8877', text: '8877 Murg'},
      {value: '8878', text: '8878 Quinten'},
      {value: '8879', text: '8879 Pizolpark (Mels)'},
      {value: '8880', text: '8880 Walenstadt'},
      {value: '8881', text: '8881 Walenstadtberg'},
      {value: '8881', text: '8881 Knoblisbühl'},
      {value: '8881', text: '8881 Tscherlach'},
      {value: '8882', text: '8882 Unterterzen'},
      {value: '8883', text: '8883 Quarten'},
      {value: '8884', text: '8884 Oberterzen'},
      {value: '8885', text: '8885 Mols'},
      {value: '8886', text: '8886 Mädris-Vermol'},
      {value: '8887', text: '8887 Mels'},
      {value: '8888', text: '8888 Heiligkreuz (Mels)'},
      {value: '8889', text: '8889 Plons'},
      {value: '8890', text: '8890 Flums'},
      {value: '8892', text: '8892 Berschis'},
      {value: '8893', text: '8893 Flums Hochwiese'},
      {value: '8894', text: '8894 Flumserberg Saxli'},
      {value: '8895', text: '8895 Flumserberg Portels'},
      {value: '8896', text: '8896 Flumserberg Bergheim'},
      {value: '8897', text: '8897 Flumserberg Tannenheim'},
      {value: '8898', text: '8898 Flumserberg Tannenbodenalp'},
      {value: '9000', text: '9000 St. Gallen'},
      {value: '9001', text: '9001 St. Gallen'},
      {value: '9002', text: '9002 St. Gallen'},
      {value: '9003', text: '9003 St. Gallen'},
      {value: '9004', text: '9004 St. Gallen'},
      {value: '9005', text: '9005 St. Gallen'},
      {value: '9006', text: '9006 St. Gallen'},
      {value: '9007', text: '9007 St. Gallen'},
      {value: '9008', text: '9008 St. Gallen'},
      {value: '9009', text: '9009 St. Gallen'},
      {value: '9010', text: '9010 St. Gallen'},
      {value: '9011', text: '9011 St. Gallen'},
      {value: '9012', text: '9012 St. Gallen'},
      {value: '9013', text: '9013 St. Gallen'},
      {value: '9014', text: '9014 St. Gallen'},
      {value: '9015', text: '9015 St. Gallen'},
      {value: '9016', text: '9016 St. Gallen'},
      {value: '9017', text: '9017 St. Gallen'},
      {value: '9018', text: '9018 St. Gallen'},
      {value: '9019', text: '9019 St. Gallen'},
      {value: '9020', text: '9020 St. Gallen'},
      {value: '9021', text: '9021 St. Gallen'},
      {value: '9022', text: '9022 St. Gallen'},
      {value: '9023', text: '9023 St. Gallen'},
      {value: '9024', text: '9024 St. Gallen'},
      {value: '9025', text: '9025 St. Gallen'},
      {value: '9026', text: '9026 St. Gallen'},
      {value: '9027', text: '9027 St. Gallen'},
      {value: '9028', text: '9028 St. Gallen'},
      {value: '9029', text: '9029 St. Gallen'},
      {value: '9030', text: '9030 Abtwil SG'},
      {value: '9030', text: '9030 Gaiserwald'},
      {value: '9032', text: '9032 Engelburg'},
      {value: '9033', text: '9033 Untereggen'},
      {value: '9034', text: '9034 Eggersriet'},
      {value: '9036', text: '9036 Grub SG'},
      {value: '9113', text: '9113 Degersheim'},
      {value: '9114', text: '9114 Hoffeld'},
      {value: '9115', text: '9115 Dicken'},
      {value: '9116', text: '9116 Wolfertswil'},
      {value: '9122', text: '9122 Mogelsberg'},
      {value: '9122', text: '9122 Ebersol'},
      {value: '9123', text: '9123 Nassen'},
      {value: '9125', text: '9125 Brunnadern'},
      {value: '9126', text: '9126 Necker'},
      {value: '9127', text: '9127 St. Peterzell'},
      {value: '9200', text: '9200 Gossau'},
      {value: '9201', text: '9201 Gossau'},
      {value: '9203', text: '9203 Niederwil SG'},
      {value: '9204', text: '9204 Andwil SG'},
      {value: '9205', text: '9205 Waldkirch'},
      {value: '9212', text: '9212 Arnegg'},
      {value: '9230', text: '9230 Flawil'},
      {value: '9231', text: '9231 Egg (Flawil)'},
      {value: '9234', text: '9234 Rindal'},
      {value: '9240', text: '9240 Uzwil'},
      {value: '9240', text: '9240 Niederglatt SG'},
      {value: '9242', text: '9242 Oberuzwil'},
      {value: '9243', text: '9243 Jonschwil'},
      {value: '9244', text: '9244 Niederuzwil'},
      {value: '9245', text: '9245 Oberbüren'},
      {value: '9246', text: '9246 Niederbüren'},
      {value: '9247', text: '9247 Henau'},
      {value: '9248', text: '9248 Bichwil'},
      {value: '9249', text: '9249 Algetshausen'},
      {value: '9249', text: '9249 Oberstetten'},
      {value: '9249', text: '9249 Niederstetten'},
      {value: '9300', text: '9300 Wittenbach'},
      {value: '9302', text: '9302 Kronbühl'},
      {value: '9303', text: '9303 Wittenbach'},
      {value: '9304', text: '9304 Bernhardzell'},
      {value: '9305', text: '9305 Berg SG'},
      {value: '9308', text: '9308 Lömmenschwil'},
      {value: '9312', text: '9312 Häggenschwil'},
      {value: '9313', text: '9313 Muolen'},
      {value: '9323', text: '9323 Steinach'},
      {value: '9327', text: '9327 Tübach'},
      {value: '9400', text: '9400 Rorschach'},
      {value: '9400', text: '9400 Rorschach Ost'},
      {value: '9401', text: '9401 Rorschach'},
      {value: '9402', text: '9402 Mörschwil'},
      {value: '9403', text: '9403 Goldach'},
      {value: '9404', text: '9404 Rorschacherberg'},
      {value: '9422', text: '9422 Staad SG'},
      {value: '9423', text: '9423 Altenrhein'},
      {value: '9424', text: '9424 Rheineck'},
      {value: '9425', text: '9425 Thal'},
      {value: '9430', text: '9430 St. Margrethen'},
      {value: '9434', text: '9434 Au SG'},
      {value: '9435', text: '9435 Heerbrugg'},
      {value: '9436', text: '9436 Balgach'},
      {value: '9437', text: '9437 Marbach SG'},
      {value: '9442', text: '9442 Berneck'},
      {value: '9443', text: '9443 Widnau'},
      {value: '9444', text: '9444 Diepoldsau'},
      {value: '9445', text: '9445 Rebstein'},
      {value: '9450', text: '9450 Lüchingen'},
      {value: '9450', text: '9450 Altstätten SG'},
      {value: '9451', text: '9451 Kriessern'},
      {value: '9452', text: '9452 Hinterforst'},
      {value: '9453', text: '9453 Eichberg'},
      {value: '9462', text: '9462 Montlingen'},
      {value: '9463', text: '9463 Oberriet SG'},
      {value: '9464', text: '9464 Rüthi (Rheintal)'},
      {value: '9464', text: '9464 Lienz'},
      {value: '9465', text: '9465 Salez'},
      {value: '9466', text: '9466 Sennwald'},
      {value: '9467', text: '9467 Frümsen'},
      {value: '9468', text: '9468 Sax'},
      {value: '9469', text: '9469 Haag (Rheintal)'},
      {value: '9470', text: '9470 Buchs SG'},
      {value: '9470', text: '9470 Werdenberg'},
      {value: '9471', text: '9471 Buchs SG'},
      {value: '9472', text: '9472 Grabs'},
      {value: '9472', text: '9472 Grabserberg'},
      {value: '9473', text: '9473 Gams'},
      {value: '9475', text: '9475 Sevelen'},
      {value: '9476', text: '9476 Weite'},
      {value: '9476', text: '9476 Fontnas'},
      {value: '9477', text: '9477 Trübbach'},
      {value: '9478', text: '9478 Azmoos'},
      {value: '9478', text: '9478 Wartau'},
      {value: '9479', text: '9479 Oberschan'},
      {value: '9479', text: '9479 Gretschins'},
      {value: '9479', text: '9479 Malans SG'},
      {value: '9500', text: '9500 Wil SG'},
      {value: '9501', text: '9501 Wil SG'},
      {value: '9512', text: '9512 Rossrüti'},
      {value: '9523', text: '9523 Züberwangen'},
      {value: '9524', text: '9524 Zuzwil SG'},
      {value: '9525', text: '9525 Lenggenwil'},
      {value: '9526', text: '9526 Zuckenriet'},
      {value: '9527', text: '9527 Niederhelfenschwil'},
      {value: '9533', text: '9533 Kirchberg SG'},
      {value: '9534', text: '9534 Gähwil'},
      {value: '9536', text: '9536 Schwarzenbach SG'},
      {value: '9552', text: '9552 Bronschhofen'},
      {value: '9601', text: '9601 Lütisburg Station'},
      {value: '9602', text: '9602 Bazenheid'},
      {value: '9602', text: '9602 Müselbach'},
      {value: '9604', text: '9604 Lütisburg'},
      {value: '9606', text: '9606 Bütschwil'},
      {value: '9607', text: '9607 Mosnang'},
      {value: '9608', text: '9608 Ganterschwil'},
      {value: '9612', text: '9612 Dreien'},
      {value: '9613', text: '9613 Mühlrüti'},
      {value: '9614', text: '9614 Libingen'},
      {value: '9615', text: '9615 Dietfurt'},
      {value: '9620', text: '9620 Lichtensteig'},
      {value: '9621', text: '9621 Oberhelfenschwil'},
      {value: '9622', text: '9622 Krinau'},
      {value: '9630', text: '9630 Wattwil'},
      {value: '9631', text: '9631 Ulisbach'},
      {value: '9633', text: '9633 Bächli (Hemberg)'},
      {value: '9633', text: '9633 Hemberg'},
      {value: '9642', text: '9642 Ebnat-Kappel'},
      {value: '9643', text: '9643 Krummenau'},
      {value: '9650', text: '9650 Nesslau'},
      {value: '9651', text: '9651 Ennetbühl'},
      {value: '9652', text: '9652 Neu St. Johann'},
      {value: '9655', text: '9655 Stein SG'},
      {value: '9656', text: '9656 Alt St. Johann'},
      {value: '9657', text: '9657 Unterwasser'},
      {value: '9658', text: '9658 Wildhaus'}
    ]
  }

  static getStGallenZipCodes() {
    return [
      '7310 Bad Ragaz',
      '7312 Pfäfers',
      '7313 St. Margrethenberg',
      '7314 Vadura',
      '7315 Vättis',
      '7317 Valens',
      '7317 Vasön',
      '7320 Sargans',
      '7323 Wangs',
      '7324 Vilters',
      '7325 Schwendi Weisstannental',
      '7326 Weisstannen',
      '8638 Goldingen',
      '8640 Rapperswil SG',
      '8640 Kempraten',
      '8645 Jona',
      '8646 Wagen',
      '8715 Bollingen',
      '8716 Schmerikon',
      '8717 Benken SG',
      '8718 Schänis',
      '8722 Kaltbrunn',
      '8723 Rufi',
      '8725 Ernetschwil',
      '8725 Gebertingen',
      '8726 Ricken SG',
      '8727 Walde SG',
      '8730 Uznach',
      '8732 Neuhaus SG',
      '8733 Eschenbach SG',
      '8734 Ermenswil',
      '8735 St. Gallenkappel',
      '8735 Rüeterswil',
      '8737 Gommiswald',
      '8738 Uetliburg SG',
      '8739 Rieden SG',
      '8872 Weesen',
      '8873 Amden',
      '8877 Murg',
      '8878 Quinten',
      '8879 Pizolpark (Mels)',
      '8880 Walenstadt',
      '8881 Walenstadtberg',
      '8881 Knoblisbühl',
      '8881 Tscherlach',
      '8882 Unterterzen',
      '8883 Quarten',
      '8884 Oberterzen',
      '8885 Mols',
      '8886 Mädris-Vermol',
      '8887 Mels',
      '8888 Heiligkreuz (Mels)',
      '8889 Plons',
      '8890 Flums',
      '8892 Berschis',
      '8893 Flums Hochwiese',
      '8894 Flumserberg Saxli',
      '8895 Flumserberg Portels',
      '8896 Flumserberg Bergheim',
      '8897 Flumserberg Tannenheim',
      '8898 Flumserberg Tannenbodenalp',
      '9000 St. Gallen',
      '9001 St. Gallen',
      '9002 St. Gallen',
      '9003 St. Gallen',
      '9004 St. Gallen',
      '9005 St. Gallen',
      '9006 St. Gallen',
      '9007 St. Gallen',
      '9008 St. Gallen',
      '9009 St. Gallen',
      '9010 St. Gallen',
      '9011 St. Gallen',
      '9012 St. Gallen',
      '9013 St. Gallen',
      '9014 St. Gallen',
      '9015 St. Gallen',
      '9016 St. Gallen',
      '9017 St. Gallen',
      '9018 St. Gallen',
      '9019 St. Gallen',
      '9020 St. Gallen',
      '9021 St. Gallen',
      '9022 St. Gallen',
      '9023 St. Gallen',
      '9024 St. Gallen',
      '9025 St. Gallen',
      '9026 St. Gallen',
      '9027 St. Gallen',
      '9028 St. Gallen',
      '9029 St. Gallen',
      '9030 Abtwil SG',
      '9030 Gaiserwald',
      '9032 Engelburg',
      '9033 Untereggen',
      '9034 Eggersriet',
      '9036 Grub SG',
      '9113 Degersheim',
      '9114 Hoffeld',
      '9115 Dicken',
      '9116 Wolfertswil',
      '9122 Mogelsberg',
      '9122 Ebersol',
      '9123 Nassen',
      '9125 Brunnadern',
      '9126 Necker',
      '9127 St. Peterzell',
      '9200 Gossau',
      '9201 Gossau',
      '9203 Niederwil SG',
      '9204 Andwil SG',
      '9205 Waldkirch',
      '9212 Arnegg',
      '9230 Flawil',
      '9231 Egg (Flawil)',
      '9234 Rindal',
      '9240 Uzwil',
      '9240 Niederglatt SG',
      '9242 Oberuzwil',
      '9243 Jonschwil',
      '9244 Niederuzwil',
      '9245 Oberbüren',
      '9246 Niederbüren',
      '9247 Henau',
      '9248 Bichwil',
      '9249 Algetshausen',
      '9249 Oberstetten',
      '9249 Niederstetten',
      '9302 Kronbühl',
      '9300 Wittenbach',
      '9303 Wittenbach',
      '9304 Bernhardzell',
      '9305 Berg SG',
      '9308 Lömmenschwil',
      '9312 Häggenschwil',
      '9313 Muolen',
      '9323 Steinach',
      '9327 Tübach',
      '9400 Rorschach',
      '9400 Rorschach Ost',
      '9401 Rorschach',
      '9402 Mörschwil',
      '9403 Goldach',
      '9404 Rorschacherberg',
      '9422 Staad SG',
      '9423 Altenrhein',
      '9424 Rheineck',
      '9425 Thal',
      '9430 St. Margrethen',
      '9434 Au SG',
      '9435 Heerbrugg',
      '9436 Balgach',
      '9437 Marbach SG',
      '9442 Berneck',
      '9443 Widnau',
      '9444 Diepoldsau',
      '9445 Rebstein',
      '9450 Lüchingen',
      '9450 Altstätten SG',
      '9451 Kriessern',
      '9452 Hinterforst',
      '9453 Eichberg',
      '9462 Montlingen',
      '9463 Oberriet SG',
      '9464 Rüthi (Rheintal)',
      '9464 Lienz',
      '9465 Salez',
      '9466 Sennwald',
      '9467 Frümsen',
      '9468 Sax',
      '9469 Haag (Rheintal)',
      '9470 Buchs SG',
      '9470 Werdenberg',
      '9471 Buchs SG',
      '9472 Grabs',
      '9472 Grabserberg',
      '9473 Gams',
      '9475 Sevelen',
      '9476 Weite',
      '9476 Fontnas',
      '9477 Trübbach',
      '9478 Azmoos',
      '9478 Wartau',
      '9479 Oberschan',
      '9479 Gretschins',
      '9479 Malans SG',
      '9500 Wil SG',
      '9501 Wil SG',
      '9512 Rossrüti',
      '9523 Züberwangen',
      '9524 Zuzwil SG',
      '9525 Lenggenwil',
      '9526 Zuckenriet',
      '9527 Niederhelfenschwil',
      '9533 Kirchberg SG',
      '9534 Gähwil',
      '9536 Schwarzenbach SG',
      '9552 Bronschhofen',
      '9601 Lütisburg Station',
      '9602 Bazenheid',
      '9602 Müselbach',
      '9604 Lütisburg',
      '9606 Bütschwil',
      '9607 Mosnang',
      '9608 Ganterschwil',
      '9612 Dreien',
      '9613 Mühlrüti',
      '9614 Libingen',
      '9615 Dietfurt',
      '9620 Lichtensteig',
      '9621 Oberhelfenschwil',
      '9622 Krinau',
      '9630 Wattwil',
      '9631 Ulisbach',
      '9633 Bächli (Hemberg)',
      '9633 Hemberg',
      '9642 Ebnat-Kappel',
      '9643 Krummenau',
      '9650 Nesslau',
      '9651 Ennetbühl',
      '9652 Neu St. Johann',
      '9655 Stein SG',
      '9656 Alt St. Johann',
      '9657 Unterwasser',
      '9658 Wildhaus'
    ]
  }
}
