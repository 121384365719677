import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kurs/:identifier',
    name: 'Event',
    props: true,
    component: () => import('../views/Event.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { requiresAuth: true },
    component: () => import('../views/Dashboard')
  },
  {
    path: '/eventdialog',
    name: 'Eventdialog',
    meta: { requiresAuth: true },
    component: () => import('../views/Eventdialog.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { requiresAdmin: true },
    component: () => import('../views/Admin.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (typeof window.gtag === 'function') {
    window.gtag('config', 'G-GPJ2KZ3YM2', {
      page_path: to.path,
    });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  } else if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters.isAuthenticated && store.getters.isAdmin) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
