import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import de from 'vuetify/src/locale/de.ts'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#008334',
        default: '#404040',
        secondary: '#193d61',
        accent: '#4ca870',
        error: '#b71c1c'
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
})

export default vuetify
