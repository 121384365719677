import axios from 'axios'

const state = {
  user: null,
  navList: [],
  operator: null,
  superAdmin: false,
  appliedFilters: {
    location: {
      title: 'Kursort',
      filters: []
    }
  }
}

const getters = {
  isAuthenticated: (state) => { return !!state.user },
  isAdmin: (state) => {
    return state.superAdmin
  },
  user: (state) => { return state.user }
}

const actions = {
  async LogIn ({ commit }, User) {
    const res = await axios.post('/auth/login', User)
    await commit('setUser', res.data)

    await axios.get('/auth/navlist')
      .then((res) => {
        commit('setNavList', res.data)
      })
  },
  async LogOut ({ commit }) {
    await axios.get('/auth/logout')
    let user = null
    commit('logout', user)
  }
}

const mutations = {
  setNavList (state, navlist) {
    state.navList = navlist
  },
  setUser (state, user) {
    state.user = user.username
    state.superAdmin = user.isAdmin
    state.operator = user.operator
  },
  logout (state) {
    state.user = null
    state.navList = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
