<template>
  <v-card>
    <v-card-text class="mt-6">
      <h2 class="mb-4">Impressum</h2>
      <h3 class="pb-2">
        Kontakt
      </h3>
      <p>
        <b>Kompetenzzentrum Integration und Gleichstellung</b><br/>
        <b>Amt für Soziales</b><br/>
        Spisergasse 41<br/>
        9001 St.Gallen<br/>
        <a href="https://www.sg.ch/gesundheit-soziales/soziales/integration/sprache/deutschkurse.html">Link zur Webseite</a>
      </p>
      <h3 class="pb-2">Haftungshinweis</h3>
      <p>
        Der Kanton St.Gallen stellt die Inhalte dieser Website als kostenlose Informationsdienstleistung zur Verfügung. Er
        übernimmt keine Haftung für Richtigkeit und Vollständigkeit der Informationen auf dieser Website; diese stellen
        insbesondere keine rechtsverbindliche Auskunft des Kantons St.Gallen dar. Werden aufgrund dieser Informationen
        dennoch Dispositionen getroffen, erfolgt dies auf eigene Verantwortung. Der Kanton St.Gallen übernimmt keine
        Haftung dafür, dass die Funktionen auf dieser Website fehlerlos sind, dass Fehler behoben werden oder dass diese
        Website bzw. der jeweilige Server frei ist von schädlichen Bestandteilen. Diese Website weist Verbindungen oder
        Bezüge (Links) zu anderen Websites auf, die von Dritten in eigner Verantwortung eingerichtet wurden und betrieben
        werden.
        <br/>
        Der Kanton St.Gallen übernimmt keine Haftung für direkte oder indirekte Schäden materieller oder ideeller Art, die
        durch den Zugriff auf diese Website und ihre Inhalte bzw. darin verlinkte andere Websites und deren Inhalte sowie
        durch die Nutzung oder Nichtnutzung dieser Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger
        Informationen auf dieser Website bzw. auf darin verlinkten anderen Websites verursacht werden.
      </p>

      <h3 class="pb-2">Sicherheitshinweis</h3>
      <p>
        Die Inhalte dieser Website können falsch oder unvollständig übertragen werden. Der Kanton St.Gallen leistet keine
        Gewähr für Vertraulichkeit und Integrität der über diese Website ausgetauschten Daten sowie für die ständige und
        unbeschränkte Verfügbarkeit der Inhalte. Er behält sich das Recht vor, die Inhalte jederzeit ohne Begründung oder
        Vorankündigung zu ändern oder zu löschen.
      </p>

      <h3 class="pb-2">Urheberrechte</h3>
      <p>
        Inhalt und Struktur dieser Website sind urheberrechtlich geschützt; alle Rechte sind vorbehalten. Das Copyright
        für auf dieser Website veröffentlichte, vom Kanton St.Gallen selbst erstellte Objekte bleibt allein beim Kanton
        St.Gallen. Für den privaten Gebrauch ist die Weiterverwendung mit vollständiger Quellenangabe erlaubt. Jede andere
        Verwendung, insbesondere das vollständige oder teilweise Reproduzieren in elektronischer oder gedruckter Form,
        derartiger Texte, Grafiken, Tondokumente und Videosequenzen für kommerzielle oder nicht-kommerzielle Zwecke ist
        nur mit vorheriger schriftlicher Zustimmung des Kantons St.Gallen (info.sk@sg.ch) gestattet.
      </p>

      <h4 class="mb-4">sg.ch Kursangebote Deutschkurse V1.0.1</h4>
      <p class="subtitle-2">Umsetzung: <a href="https://goelditechnology.ch" target="_blank">goeldi technology</a>.<br>
        Kontakt: <a href="mailto:support@goelditechnology.ch">support@goelditechnology.ch</a></p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="$emit('closeSheet')"
      >
        Schliessen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import {showSnack} from '../globalActions'

export default {
  name: 'LegalNotice',
  props: [],
  components: {},
  data: function () {
    return {
      data: null
    }
  },
  mounted () {

  },
  watch: {},
  methods: {}
}
</script>

<style scoped>

</style>
