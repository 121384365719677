<template>
  <v-card>
    <v-card-text class="mt-6">
      <h2 class="mb-4">Datenschutz</h2>
      <p class="font-weight-bold">
        Nach Art. 2 Abs. 1 lit. g) der Verfassung des Kantons St.Gallen und den datenschutzrechtlichen Bestimmungen des
        Kantons (Datenschutzgesetz, sGS 142.1) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz
        vor Missbrauch ihrer persönlichen Daten. Wir halten diese Bestimmungen ein.
      </p>
      <p>
        Persönliche Daten werden streng vertraulich behandelt und weder an Dritte verkauft noch weiter gegeben. In enger
        Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich vor fremden
        Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen. Beim Zugriff auf unsere Website werden folgende
        Daten in Logfiles gespeichert: IP-Adresse, Port, Datum, Uhrzeit, Browser-Anfrage und allg. übertragene
        Informationen zum Betriebssystem bzw. Browser. Diese Nutzungsdaten bilden die Basis für anonyme, statistische
        Auswertungen, die Trends erkennbar machen, anhand derer wir unsere Angebote verbessern können.
      </p>
      <h3 class="pb-2">Nutzung von Siteimprove Analytics</h3>
      <p>
        Diese Website benutzt Siteimprove Analytics, einen Webanalysedienst, der von Siteimprove zur Verfügung gestellt
        wird. Siteimprove Analytics nutzt "Cookies" - Textdateien, die auf Ihrem Rechner oder Smartphone gespeichert
        werden, um dem Kanton St.Gallen dabei zu helfen zu analysieren, wie Besucher die Website benutzen. Die
        Information, die durch die Cookies über die Website-Nutzung der Besucher erstellt wird, wird von Siteimprove auf
        Servern in Dänemark gespeichert und verarbeitet.
        <br/>
        IP-Adressen werden vollständig anonymisiert, bevor erhobene Daten über die Siteimprove Suite für den Kanton
        St.Gallen einsehbar sind. Eine Umkehrung der Anonymisierung der IP-Adressen und eine Zuordnung der IP-Adressen
        zu erhobenen Daten ist nicht möglich.
        <br/>
        Der Kanton St.Gallen wird diese Informationen nutzen, um das Benutzerverhalten seiner Websitebesucherinnen und
        -besucher auszuwerten, Berichte darüber zu erstellen und um das Website-Erlebnis für seine Besucher zu
        verbessern. Siteimprove wird diese Informationen nicht an Dritte weitergeben oder sie für Marketing- oder
        Werbezwecke jeglicher Art benützen.
      </p>
      <p>
        Die folgenden Cookies von Siteimprove werden auf dieser Website eingesetzt:
      </p>
      <h3 class="pb-2">Name des Cookies: nmstat</h3>
      <p>
        Typ: Persistent – läuft nach 1000 Tagen ab
      </p>
      <p>
        Über das Cookie: Dieses Cookie wird genutzt, um das Verhalten der Besucher auf der Website festzuhalten. Es wird
        genutzt um Statistiken über die Websitenutzung zu sammeln, wie zum Beispiel wann der Besucher die Website
        zuletzt besucht hat. Das Cookie enthält keine personenbezogenen Daten und wird einzig für die Websiteanalyse
        eingesetzt.
      </p>
      <h3 class="pb-2">Name des Cookies: siteimproveses</h3>
      <p>
        Typ: Sitzungscookie
      </p>
      <p>
        Über das Cookie: Dieses Cookie wird dafür eingesetzt, um die Abfolge an Seiten zu verfolgen, die ein Besucher im
        Laufe seines Besuchs auf der Website ansieht. Das Cookie enthält keine personenbezogenen Daten und wird einzig
        für die Websiteanalyse eingesetzt.
      </p>

      <p class="font-weight-bold">
        Durch die Benutzung dieser Website erklärt sich der Besucher bzw. die Besucherin mit der Verarbeitung seiner
        bzw. ihrer Daten zu den oben beschriebenen Zwecken einverstanden.
      </p>

      <p>
        Stand der Datenschutzerklärung: 25.11.2023
      </p>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="blue darken-1"
          text
          @click="$emit('closeSheet')"
      >
        Schliessen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import {showSnack} from '../globalActions'

export default {
  name: 'PrivacyPolicy',
  props: [],
  components: {},
  data: function () {
    return {
      data: null
    }
  },
  mounted() {

  },
  watch: {},
  methods: {}
}
</script>

<style scoped>

</style>
