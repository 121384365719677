export class Format {
  static numberWithDelimiter (x) {
    if (x < 0) x = 0
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
  }

  static isNumeric (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
}

export class SubsidyCalculation {
  static getSubsidyAmountA() {
    return 8
  }

  static getSubsidyAmountB() {
    return 7
  }
  static getCostWithSubsidy (costs, lessonCount , courseLevel) {
    const subsidyAmountA = 8
    const subsidyAmountB = 7
    // return 0 if lesson count or costs are not provided
    if (!costs || !lessonCount || !Format.isNumeric(costs) || !Format.isNumeric(lessonCount)) {
      return 0
    }

    costs = parseFloat(costs)
    lessonCount = parseFloat(lessonCount)

    courseLevel = courseLevel.split(',').map( Number )
    if (courseLevel.length === 1) {
      if (courseLevel[0] === 0) {
        return costs - (lessonCount * subsidyAmountA)
      } else if (courseLevel[0] >= 0 && courseLevel[0] <= 5) {
        return costs - (lessonCount * subsidyAmountB)
      } else {
        return 0
      }
    } else {
      // todo multiple course levels
      return 0
    }

  }
}
